
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';
import uniqBy from 'lodash/uniqBy';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import Form from '@/components/mixins/formChecks';
import { RoleNames, Roles, RouteNames, teamRoleNamesById, TextfieldInfo } from '@/constants';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { openSnackbar } from '@/utils/components';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import { HELP_ROOT_PATH } from '@/constants/help.constants';
import { UserModule } from '@/store/modules/user.module';
import UserCircle from '@/components/UserCircle.vue';
import { BaseUser } from '@/types/users.types';
import { CreateTeamRequest, TeamActiveDirectoryGroup, TeamMember } from '@/types/teams.types';
import { SelectOption } from '@/components/form/form.types';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { TeamsModule } from '@/store/modules/teams.module';
import { EnumsModule } from '@/store/modules/enums.module';

@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GPeoplePicker,
    UserCircle,
    GSelect,
  },
})
export default class NewTeamView extends mixins(Form) {
  private loading = false;
  private teamName = '';
  private teamDescription = '';
  private addMembers: TeamMember[] = [];
  private teamMembers: TeamMember[] = [];
  public newADGroupId = '';
  public teamADGroups: TeamActiveDirectoryGroup[] = [];
  public nameLabel: string = TextfieldInfo.teamNameLabel;
  public teamNamePlaceholder: string = TextfieldInfo.teamNamePlaceholder;
  public teamValidationMessage: string = TextfieldInfo.teamValidationMessage;
  public descriptionLabel: string = TextfieldInfo.descriptionLabel;
  public teamDescriptionPlaceholder: string = TextfieldInfo.teamDescriptionPlaceholder;
  public teamDescriptionValidationMessage: string = TextfieldInfo.teamDescriptionValidationMessage;
  public teamRoleNames: SelectOption[] = [
    { value: Roles.TeamOwner + '', label: RoleNames.TeamOwner },
    { value: Roles.TeamMember + '', label: RoleNames.TeamMember },
  ];
  public tooltipInfo: string = TextfieldInfo.TeamRoleInfoHelp;

  back() {
    this.$router.safeBack(this.closeRoute);
  }

  backToTeams() {
    this.$router.push({ name: RouteNames.MyTeams });
  }

  addTeamMembers() {
    this.addMembers.forEach(member => {
      this.teamMembers.push({
        ...member,
        roleName: RoleNames.TeamMember,
        roleId: Roles.TeamMember,
      });
    });
    this.teamMembers = uniqBy(this.teamMembers, member => member.mudId);
    this.addMembers = [];
  }

  addADGroup() {
    if (!this.newADGroupId || this.newADGroupId === '') {
      return;
    }

    if (!this.teamADGroups.find(t => t.groupId === Number(this.newADGroupId))) {
      const groupName = this.userActiveDirectoryGroups?.find(
        ref => ref.value === Number(this.newADGroupId),
      )?.label;
      if (groupName) {
        this.teamADGroups.push({
          groupId: Number(this.newADGroupId),
          groupName,
          roleId: EnumsModule.enums.role.TEAMOWNER.id,
        });
      }
    }

    this.newADGroupId = '';
  }

  updateADGroupRole(teamADGroup: TeamActiveDirectoryGroup, roleId: string) {
    const newTeamRoleId = Number(roleId);
    teamADGroup.roleId = newTeamRoleId;
  }

  deleteADGroup(teamADGroup: TeamActiveDirectoryGroup) {
    const itemIndex = this.teamADGroups.findIndex(
      t =>
        t.groupId === teamADGroup.groupId
    );
    if (itemIndex > -1) {
      this.teamADGroups.splice(itemIndex, 1);
    }
  }

  updateUserRole(m: TeamMember, e: string) {
    const newTeamRoleId = Number(e);
    m.roleId = newTeamRoleId;
    m.roleName = teamRoleNamesById[newTeamRoleId];
  }

  deleteMember(member: TeamMember): void {
    this.teamMembers = this.teamMembers.filter((m: TeamMember) => {
      return m.mudId !== member.mudId;
    });
  }

  /*
  async createTeam(): Promise<void> {
    await TeamsModule.createTeam(this.team)
      .then(() => {
        this.$router.push({ name: RouteNames.MyTeams });
        openSnackbar.call(this, 'New Team Created');
      })
      .catch(err => {
        this.$router.push({ name: RouteNames.MyTeams });
        openSnackbar.call(this, err.message, { type: 'error' });
      });
  }*/

  async createTeam(): Promise<void> {
    try {
      await TeamsModule.createTeam(this.team);
      await this.$router.push({ name: RouteNames.MyTeams });
      openSnackbar.call(this, 'New Team Created');
    } catch (err:any){
      openSnackbar.call(this, err.message, { type: 'error' });
    }
  }

  get team(): CreateTeamRequest {
    return {
      teamName: this.teamName,
      teamDescription: this.teamDescription,
      iconUrl: '',
      createUserId: this.currentUser.userId,
      teamMembers: this.teamMembers,
      teamADGroups: this.teamADGroups,
    };
  }

  get canCreate(): boolean {
    return (
      !!this.teamDescription &&
      !!this.teamName &&
      (
        (
          this.teamMembers.length >= 1
          && !this.noTeamOwners
        )
        ||
        ( this.teamADGroups.length >= 1
          && !this.noADOwners
        )
      )
    );
  }

  get noTeamOwners(): boolean {
    return (
      this.teamMembers.filter(member => {
        return member.roleId === Roles.TeamOwner;
      }).length < 1
    );
  }

  get noADOwners(): boolean {
    return (
      this.teamADGroups.filter(group => {
        return group.roleId === Roles.TeamOwner;
      }).length < 1
    );
  }

  get helpRoute(): RawLocation {
    return `/${HELP_ROOT_PATH}`;
  }

  get closeRoute(): RawLocation {
    return {
      name: RouteNames.MyTeams,
    };
  }

  notCurrentUser(member: TeamMember): boolean {
    return member.mudId !== this.currentUserMudId;
  }

  isCurrentUser(member: TeamMember): boolean {
    return member.mudId === this.currentUserMudId;
  }

  get currentUserMudId(): string {
    return this.currentUser.mudId;
  }

  get currentUser(): BaseUser {
    return UserModule.user;
  }

  get userActiveDirectoryGroups(): { value: number; label: string }[] {
    return (UserModule.user?.groups || []).map(g => {
      return {
        value: g.id,
        label: g.name,
      };
    });
  }

  created() {
    this.teamMembers.push({
      roleId: Roles.TeamOwner,
      mudId: this.currentUserMudId,
      email: this.currentUser.email,
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      fullName: this.currentUser.fullName,
    });
  }

  get ownerRoleName(): string {
    return RoleNames.TeamOwner;
  }

  get disabled(): boolean {
    return this.loading;
  }

  get submitDisabled(): boolean {
    return !this.teamName || !this.teamDescription;
  }
}
